.container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.container .search {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    width: 100%;
    min-height: 40px;
}

.container .search .inputSearch {
    padding: 0;
    height: 30px;
    flex-grow: 1;
}

.container .search .chip {
    display: flex;
    align-items: center;
    gap: 15px;
    border-radius: 4px;
    background: #f7f7f8;
    padding: 6px 8px;
}

.container .search .chip span {
    position: relative;
    width: 14px;
    height: 14px;
    background: #9b9b9b;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container .search .chip span:hover {
    background: #bdb6b6;
}

.container .search .chip span i {
    position: absolute;
    color: #fff;
    font-size: 7px;
}

.container .search input {
    border: none;
    width: fit-content;
}

.container .options {
    width: calc(100% + 1px);
    position: absolute;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    top: 105%;
    left: 0;
    z-index: 10000;
}

.options.show{
    max-height: 204px;
    padding: 10px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    overflow: auto;
}

.container .options .option {
    padding: 12px 10px;
    cursor: pointer;
}

.container .options .option b {
    font-weight: 800;
}

.container .options .option:hover {
    background: #ecf1fc;
}

.container .options .selected {
    background: #f7f7f8;
}

.container .options .disabled {
    cursor: default;
}
