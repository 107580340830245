.container {
    background: #ffffff;
    border-radius: 10px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
}


.container .inputs {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
}


.container .inputs .form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    justify-content: center;
    align-items: center;
    height: 300px;
    padding: 48px 16px;
    border: 1px solid #012bd9;
    border-radius: 8px;
    color: #012bd9;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s;
}

footer {
    display: flex;
    gap: 20px;
    justify-content: flex-start;
}


@media screen and (max-width: 768px) {
    .container {
        padding: 24px 12px;
    }

    .container .inputs {
        flex-direction: column;
    }

    .container .inputs .form {
        width: 100%;
    }

    .container .inputs .form {
        height: 280px;
    }
}
