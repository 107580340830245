.card {
    padding: 24px 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    background: #ffff;
}

.card .rightSide .top i {
    padding-right: 2px;
}

.card .rightSide .top {
    display: flex;
    align-items: center;
}

.card .rightSide .top h5 {
    font-size: 12px;
    font-weight: 800;
}

.card .moreIcon {
    position: relative;
}

.card .moreIcon i {
    position: relative;
    color: #9b9b9b;
    cursor: pointer;
    transition: all 0.3s;
}

.card .moreIcon i:hover {
    color: #012bd9;
}

.card .rightSide {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.card .rightSide .bottom {
    display: flex;
    align-items: center;
    gap: 10px;
}

.card .rightSide .bottom h6 {
    color: #9b9b9b;
    font-size: 12px;
}

.card .rightSide .bottom span {
    padding: 3px 10px;
    border-radius: 15px;
    color: #ffff;
    font-size: 12px;
    transition: all 0.3s;
}

.card .rightSide .bottom .night {
    background: #1977ac;
}

.card .rightSide .bottom .day {
    background: #1cb6e0;
}

.card .divider {
    display: block;
    width: 100%;
    height: 1px;
    background: #f7f7f8;
}

.card .gray {
    background: #f9fafb;
    border-radius: 4px;
}

.card .mobileTable {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
}

.card .mobileTable .summary {
    justify-content: flex-end;
    text-align: right;
    padding-left: 20px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 14px;
}

.card .mobileTable .summary ol {
    list-style: auto;
}

.card .mobileTable .summary strong {
    font-weight: 800;
}

.card .mobileTable p,
.card .mobileTable span,
.card .mobileTable .summary ul,
.card .mobileTable .summary ul li,
.card .mobileTable .summary ol,
.card .mobileTable .summary ol li {
    font-size: 14px;
}

.card .mobileTable .wrapperNames {
    display: flex;
}

.card .mobileTable h3 {
    font-size: 14px;
    font-weight: 800;
    line-height: 1.3;
    text-align: right;
    padding-left: 5px;
}

.card .mobileTable h4 {
    line-height: 1.3;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
}

.card .mobileTable .summary {
    display: flex;
    width: calc(100% - 130px);
    word-break: break-word;
}

.files {
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    color: #ffff;
    font-size: 12px;
}

.files i {
    color: #344054;
}
