.select {
    display: flex;
    gap: 10px;
    cursor: pointer;
    position: relative;
}

.options {
    position: absolute;
    z-index: 10000;
    top: 105%;
    left: 0;
    width: calc(100% + 1px);
    background: #ffff;
    border-radius: 8px;
    max-height: 0;
    min-width: 100px;
    transition: all 0.4s;
    overflow: hidden;
}

.options.show {
    max-height: 154px;
    padding: 10px 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    overflow: auto;
}

.options li {
    height: 36px;
    padding: 10px 24px;
    cursor: pointer;
}

.options li:hover {
    background: #ecf1fc;
}
