.files {
    width: 100%;
    background: #fff;
}

.files .tableContainer {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 30px;
}

.files .tableRow.heading {
    font-weight: 900;
    background: #f9fafb;
    padding: 25px 15px 25px 20px;
    align-items: center;
    transition: all 0.3s;
}

.files .tableRow {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
    padding: 20px 15px 20px 20px;
    border-bottom: 1px solid #f7f7f8;
}

.tableRow:last-child {
    border-bottom: 0;
}

.files .tableRow:hover {
    background: #f9fafb;
}

.files .tableRow:hover .rowItem.lastColumn i {
    color: #072194;
}

.rowItem {
    display: flex;
    flex: 1;
    font-size: 14px;
    line-height: 1.3;
    padding-right: 10px;
}

.files .tableRow .rowItem.lastColumn {
    flex: none;
    margin-left: auto;
}

.files .tableRow .rowItem.lastColumn i {
    color: #012bd9;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.3s;
}

.files .tableRow .rowItem.lastColumn i:hover {
    color: #072194;
}

.files .rowItem.firstColumn {
    flex: none;
    margin-right: 10px;
}

.files .rowItem.secondColumn {
    word-break: break-all;
}

.files .rowItem.fourthColumn {
    flex: 3;
    max-width: 500px;
}

.files .fifthColumn {
    width: 35px;
}

.no-files-attached {
    opacity: 0.5;
    pointer-events: none;
}

@media screen and (max-width: 1023px) {
    .files .tableContainer {
        width: 97%;
    }
    .files .tableRow.heading {
        display: none;
    }

    .files .tableRow {
        flex-direction: column;
        position: relative;
        border: 1px solid #f7f7f8;
        border-radius: 4px;
        margin-left: 25px;
        margin-bottom: 12px;
    }

    .files .tableRow .rowItem {
        padding-bottom: 10px;
        padding-right: 0;
    }

    .files .rowItem.firstColumn label {
        position: absolute;
        top: 0;
        left: -30px;
    }

    .files .rowItem.secondColumn {
        font-weight: 800;
        width: 95%;
        padding-right: 10px;
    }

    .files .rowItem.thirdColumn {
        color: #9b9b9b;
        border-bottom: 0.5px solid #f7f7f8;
        padding-bottom: 15px;
        margin-bottom: 15px;
    }

    .files .rowItem.fourthColumn {
        flex-direction: column;
        padding-bottom: 0;
    }

    .files .rowItem.fourthColumn::before {
        content: 'File Description';
        display: block;
        font-weight: 800;
        margin-bottom: 8px;
    }

    .files .tableRow .rowItem.lastColumn {
        position: unset;
        padding-left: 30px;
    }

    .files .tableRow .rowItem.lastColumn i {
        position: absolute;
        top: 30px;
        right: 10px;
    }

    .files .tableRow:hover {
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
        background: none;
    }
}
