.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.wrapper.white {
    background: #fff;
    animation: fade 4s 2s linear;
    z-index: 1000;
}

.wrapper.blue {
    background: linear-gradient(90deg, #012bd9 0%, #1cb6e0 100%);
    animation-name: fadeReverse;
    animation-delay: 2s;
    animation-duration: 2s;
    animation-direction: linear;
    z-index: 2000;
}

.wrapper.blue.hide {
    opacity: 0;
    z-index: 900;
}

.wrapper .content {
    max-width: 600px;
    margin: auto;
    margin-top: calc(50vh);
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
}

.wrapper .content .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
}

.wrapper .content .logo figure {
    display: flex;
    flex-direction: column;
    gap: 28px;
}

.wrapper .content .logo figure img {
    width: 100%;
}

.wrapper .content .logo h3 {
    color: #fff;
    font-weight: 700;
    letter-spacing: 0.3px;
}

.wrapper .content .card {
    background: transparent;
    padding: 0 20px;
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.wrapper .content .card .title {
    text-align: center;
    font-size: 38px;
    font-weight: 800;
}

.wrapper .content .card p {
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    max-width: 300px;
    text-align: center;
}

@keyframes fade {
    0% {
        background-color: linear-gradient(90deg, #012bd9 0%, #1cb6e0 100%);
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        background-color: #fff;
        opacity: 1;
    }
}

@keyframes fadeReverse {
    0% {
        opacity: 1;
        background-color: #fff;
    }
    100% {
        opacity: 0;
        background-color: linear-gradient(90deg, #012bd9 0%, #1cb6e0 100%);
    }
}
