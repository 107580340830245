.container {
    max-width: 460px;
    margin: auto;
    margin-top: calc(50vh - 80px);
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
}

.container p {
    font-size: 18px;
    line-height: 28px;
    text-align: center;
}

.container .title {
    text-align: center;
    font-size: 36px;
    font-weight: 800;
}

.container .icon {
    font-size: 72px;
    background: -webkit-linear-gradient(90deg, #012bd9 0%, #1cb6e0 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    transform: rotate(15.2deg);
}
