.container {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.container .summary {
    width: 100%;
    cursor: pointer;
    padding: 25px 20px;
}

.container .summary.disabled {
    cursor: default;
}

.container .content {
    padding: 0 20px;
    overflow: hidden;
    max-height: 0;
    transition: all 0.7s;
}

.container .content.expanded {
    max-height: 1000px;
}
