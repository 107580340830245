.container {
    position: absolute;
    top: 45px;
    right: 0;
    min-width: 326px;
    background: #ffffff;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    padding: 18px 22px;
    z-index: 9999;
}

.container .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #f7f7f8;
    padding-bottom: 10px;
    margin-bottom: 10px;
    line-height: 1.2;
}

.container .header h5 {
    font-weight: 800;
}

.container .header button i {
    margin-right: 2px;
}

.container ul {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.container ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 8px 0; */
    cursor: pointer;
}

.container ul li .labelContainer {
    display: flex;
    align-items: center;
}

.container ul li .labelContainer i {
    color: #c4c4c4;
    font-size: 18px;
}

.container ul li .labelContainer i::before {
    margin-left: 0;
}

.container ul li .labelContainer h5 {
    padding-top: 2px;
    transition: all 0.3s;
}

.container ul li:hover .labelContainer h5,
.container ul li:hover .labelContainer i {
    color: #012bd9;
}

@media screen and (max-width: 768px) {
    .container {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 85%;
    }
    .container ul {
        gap: 30px;
    }
}
