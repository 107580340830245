.pagesSelector {
    display: flex;
    align-items: center;
    gap: 45px;
}

.pagesSelector span {
    font-size: 18px;
    font-weight: 600;
    color: #344054;
    cursor: pointer;
}

.pagesSelector span:hover {
    color: #012bd9;
}

.pagesSelector span.active {
    color: #012bd9;
}

.pagesSelector .threeDots {
    display: flex;
    align-items: center;
    gap: 5px;
}

.pagesSelector .threeDots .dot {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #03031f;
    cursor: default;
}
