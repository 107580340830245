.container {
    margin-top: 24px;
    border: 1px solid #ecede8;
    border-radius: 10px;
    background: #ffff;
}

.container .summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.container .summary.expanded {
    border-radius: 10px 10px 0 0;
}

.container .summary.disabled {
    cursor: default;
}

.container .summary .title {
    display: flex;
    align-items: center;
    gap: 6px;
}

.container .summary .title h3 {
    font-weight: 900;
    font-size: 20px;
}

.container .summary .title i {
    color: #012bd9;
    font-size: 20px;
}

.container .summary.disabled .title span,
.container .summary.disabled .title h3,
.container .summary.disabled .title i {
    color: #ecede8;
    background-color: transparent;
}

.container .summary .title i::before {
    margin-left: 0;
}

.container .summary .title span {
    display: flex;
    justify-content: center;
    padding: 3px 12px;
    background: #012bd9;
    border-radius: 20px;
    color: #ffff;
    font-size: 12px;
}

.container .summary .rightTop {
    display: flex;
    align-items: center;
    gap: 24px;
}

.container .summary .rightTop .downloadButton {
    display: flex;
    align-items: center;
    color: #012bd9;
    cursor: pointer;
    font-weight: 700;
}

.container .summary .rightTop .downloadButton span {
    background: none;
    width: auto;
    height: auto;
    color: #012bd9;
}

.container .summary .rightTop .downloadButton i {
    font-size: 18px;
}

.container .summary .rightTop .downloadButton:hover,
.container .summary .rightTop .downloadButton span:hover {
    color: #0122ae;
}

.container .summary button {
    width: auto;
    height: auto;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container .summary button span {
    font-size: 16px;
    background: #f2f4fd;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: block;
}

.container .summary button:hover span {
    color: #012bd9;
}

.container .summary.disabled .rightTop .roundedButton {
    cursor: default;
}

.container .summary.summary.disabled .rightTop .roundedButton:hover span {
    color: #344054;
}

.container .summary .rightTop .roundedButton {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #f2f4fd;
}

@media screen and (max-width: 768px) {
    .container {
        margin-top: 16px;
    }

    .container .summary .rightTop {
        gap: 14px;
    }

    .container .summary .rightTop .downloadButton span {
        display: none;
    }

    .container .summary .title h3 {
        font-size: 16px;
    }

    .container .summary .title i {
        font-size: 18px;
    }

    .container .summary .title span {
        margin-left: 4px;
    }

    .container .summary .rightTop .downloadButton i {
        font-size: 18px;
    }
}
