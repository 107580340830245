.container {
    display: flex;
    gap: 10px;
}

.container i {
    font-size: 20px;
}

.container .body {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.container .body h4 {
    font-size: 16px;
    font-weight: 900;
}

.container .body span {
    font-size: 16px;
    font-weight: 400;
}
