.card {
    padding: 24px 16px;
    display: flex;
    gap: 16px;
    flex-direction: column;
    background: #ffff;
}

.card .rightSide .top i {
    padding-right: 2px;
}

.card .rightSide .top {
    display: flex;
    align-items: center;
}

.card .rightSide .top h5 {
    font-size: 12px;
    font-weight: 800;
}

.card .divider {
    display: block;
    width: 100%;
    height: 1px;
    background: #f7f7f8;
}

.card .mobileTable {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
}

.card .mobileTable .wrapperNames {
    display: flex;
}

.card .gray {
    background: #f9fafb;
    border-radius: 4px;
}

.card .mobileTable h3 {
    font-size: 14px;
    font-weight: 800;
    line-height: 1.3;
    text-align: right;
    padding-left: 5px;
}

.card .mobileTable h4 {
    line-height: 1.3;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
}