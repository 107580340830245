/*Radio*/

.radio {
    width: auto;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.radio input[type='radio'] {
    opacity: 0;
    position: absolute;
    left: 0;
    top: 7px;
    margin: 0;
    z-index: 1;
    cursor: pointer;
    width: 15px;
    height: 15px;
}

.radio span {
    position: relative;
    top: -1px;
    background: #fff;
    border: 1px solid #012bd9;
    border-radius: 100%;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px;
    width: 15px;
    height: 15px;
}

.radio input[type='radio']:checked + span:after {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 9px;
    height: 9px;
    background: #012bd9;
    border-radius: 100%;
}
