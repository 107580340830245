.modalContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background: #ffffff;
    width: 250px;
    right: 0;
    top: 58px;
    z-index: 2000;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    padding: 25px;
    position: absolute;
}

.modalContent .chooserContainer {
    display: flex;
    align-items: center;
    gap: 30px;
}

.modalContent .chooserContainer i {
    color: #012bd9;
    font-size: 12px;
    cursor: pointer;
}

.modalContent .chooserContainer .simpleChooser {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
}

.modalContent footer {
    display: flex;
    justify-content: flex-end;
    gap: 20px;
}

.modalContent footer span {
    color: #012bd9;
    cursor: pointer;
}

.modalContent footer span:hover {
    color: #012b95;
}
