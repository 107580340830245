.container {
    border: 1px solid #ecede8;
    background: #ffff;
    border-radius: 10px;
}

.container .summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;
}

.container .expanded {
    border-radius: 10px 10px 0 0;
}

.container .summary .title {
    display: flex;
    align-items: center;
    gap: 6px;
}

.container .summary .title i {
    color: #012bd9;
    font-size: 20px;
}
.container .summary .title i::before {
    margin-left: 0;
}

.container .summary .title h3 {
    font-weight: 900;
    font-size: 20px;
}

.container .summary button {
    width: auto;
    height: auto;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container .summary button span {
    font-size: 16px;
    background: #f2f4fd;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: block;
}

.container .summary button:hover span {
    color: #012bd9;
}

.container .information {
    border-top: 1px solid #f7f7f8;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px 0;
}

.container .information .field {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 250px;
    word-break: break-word;
    line-height: 20px;
}

.container .information .field h4 {
    font-weight: 800;
}

.container .shift::before {
    content: '';
    display: block;
    width: calc(100% + 62px);
    height: 10px;
    background: #f7f7f8;
    border: 1px solid #f7f7f8;
    transform: translateX(-31px);
}

.container .shift {
    border-radius: 0 0 10px 10px;
}

.container .shift .title {
    display: flex;
    align-items: center;
    gap: 6px;
    padding-top: 30px;
}

.container .shift .title h3 {
    font-weight: 900;
    font-size: 20px;
}

.container .shift .title i {
    color: #012bd9;
    font-size: 20px;
}

.container .shift .title i::before {
    margin-left: 0;
}

.container .shift .text {
    margin: 24px 0;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    padding: 32px;
    text-transform: uppercase;
    line-height: 1.4;
    word-wrap: break-word;
}

.container .shift .text ol,
.container .shift .text ul {
    list-style: auto;
}

.container .shift .text:hover {
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 1023px) {
    .container .information {
        flex-direction: column;
        align-items: start;
        gap: 24px;
    }

    .container .information .field {
        max-width: none;
        gap: 12px;
    }
}

@media screen and (max-width: 768px) {
    .container .summary .title h3 {
        font-size: 16px;
    }

    .container .summary .title i {
        font-size: 18px;
    }

    .container .shift .title h3 {
        font-size: 16px;
    }

    .container .shift .title i {
        font-size: 18px;
    }
}
