.container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container .loader {
    font-size: 10px;
    text-indent: -9999em;
    border-radius: 50%;
    background: #4766d5;
    background: -moz-linear-gradient(left, #4766d5 10%, rgba(255, 255, 255, 0) 42%);
    background: -webkit-linear-gradient(left, #4766d5 10%, rgba(255, 255, 255, 0) 42%);
    background: -o-linear-gradient(left, #4766d5 10%, rgba(255, 255, 255, 0) 42%);
    background: -ms-linear-gradient(left, #4766d5 10%, rgba(255, 255, 255, 0) 42%);
    background: linear-gradient(to right, #4766d5 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    -webkit-animation: load3 1.4s infinite linear;
    animation: load3 1.4s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
}
.container .loader:before {
    width: 50%;
    height: 50%;
    background: #4766d5;
    border-radius: 100% 0 0 0;
    position: absolute;
    top: 0;
    left: 0;
    content: '';
}
.container .loader:after {
    background: #f7f7f8;
    width: 80%;
    height: 80%;
    border-radius: 50%;
    content: '';
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
@-webkit-keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    75% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes load3 {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    75% {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.container span {
    position: absolute;
    color: #4766d5;
    font-size: 18px;
}
