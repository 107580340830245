.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 25px;
    background: #ffff;
    border-radius: 20px 20px 0 0;
    width: 100%;
    /* position: relative; */
    z-index: 3;
}

.container h3 {
    width: fit-content;
    font-weight: 800;
    font-size: 20px;
}

.options {
    position: relative;
}