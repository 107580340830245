.overlay {
    background: transparent;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    transition: all 0.3s;
    cursor: default;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;
}

.grayOverlay {
    background: rgba(52, 64, 84, 0.6);
    border-radius: 10px;
}
