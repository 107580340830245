.container {
    position: relative;
    display: flex;
    align-items: center;
    border: 1px solid #9b9b9b;
    width: 100%;
    border-radius: 4px;
    padding: 8px 10px;
    min-height: 56px;
    height: inherit;
}

.container label {
    position: absolute;
    font-size: 12px;
    background: #ffff;
    padding: 0 5px;
    top: -7px;
    left: 10px;
    color: #9b9b9b;
    transition: all 0.3s;
}

.container .icon {
    position: absolute;
    top: 20px;
    right: 10px;
    color: #9b9b9b;
    transition: all 0.3s;
}

.container.focused label {
    color: #012bd9;
}

.focused {
    border: 1px solid #012bd9;
}

.error {
    border: 1px solid #cb5555;
}

.resize {
    min-height: unset;
}

.container.error label {
    color: #cb5555;
}

.container.error p {
    font-size: 13px;
    font-weight: 500;
    position: absolute;
    bottom: -20px;
    left: 6px;
    color: #cb5555;
}
