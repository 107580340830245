.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 25px;
    background: #ffff;
    border-radius: 20px 20px 0 0;
    width: 100%;
    /* position: relative; */
    z-index: 3;
}

.container h3 {
    width: fit-content;
    font-weight: 800;
    font-size: 20px;
}

.container .filters {
    display: flex;
    align-items: center;
    gap: 16px;
}

.container .filters .search {
    position: relative;
    display: flex;
    align-items: center;
}

.container .filters .search i {
    position: absolute;
    left: 5px;
    color: #afb4bc;
}

.container .filters .search input {
    border: 1px solid #f9fafb;
    border-radius: 10px;
    background: #f9fafb;
    height: 40px;
    padding-left: 30px;
}

.container .filters .search input::placeholder {
    font-size: 14px;
}

.container .filters .search input:focus ~ i {
    color: #344054;
}

.container .filters .rightSide {
    display: flex;
    align-items: center;
    gap: 16px;
}

.container .filters .rightSide .modalButton {
    position: relative;
}

.container .filters .rightSide .modalButton .settings {
    background: #f9fafb;
    border: 1px solid #f9fafb;
    border-radius: 10px;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0 10px;
    transition: all 0.3s;
    cursor: pointer;
    position: relative;
}

.container .filters .rightSide .modalButton .settings .sortDot {
    width: 14px;
    height: 14px;
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: #cb5555;
    border-radius: 50%;
}

.container .filters .rightSide .modalButton:hover .settings,
.container .filters .rightSide .modalButton.focusSettings .settings {
    background: #ecf1fc;
    border: 1px solid #012bd9;
    color: #012bd9;
}

.container .filters .rightSide .modalButton.focusSettings .settings .settingIcon {
    transform: rotate(180deg);
}

.container .filters .rightSide .modalButton .settings .settingIcon {
    font-size: 20px;
    transition: all 0.3s;
    transform: rotate(0);
}

.container .filters .rightSide .modalButton .settings .settingIcon {
    font-size: 12px;
}

.container .filters .rightSide .modalButton .settings h6 {
    font-size: 14px;
    margin: 0 6px 0 0;
}

.container .filters .rightSide .modalButton:hover .settings h6 {
    color: #012bd9;
}

@media screen and (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: start;
        gap: 16px;
        box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
        border-radius: 0;
    }

    .container h3 {
        font-size: 16px;
    }

    .container .filters {
        width: 100%;
        justify-content: space-between;
    }

    .container .filters h6 {
        display: none;
    }

    .container .filters .search,
    .container .filters .search input {
        width: 100%;
    }

    .container .filters .rightSide .modalButton {
        position: inherit;
    }
}
