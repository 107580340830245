/* unchecked state */
.unchecked {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid #9b9b9b;
    border-radius: 4px;
    cursor: pointer;
}

.unchecked:hover {
    border: 1px solid #012bd9;
}

.unchecked input[type='checkbox'] {
    display: none;
}

.unchecked .checkmark {
    display: none;
}

/* checked state */
.checked {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: #012bd9;
    border: 1px solid #012bd9;
    cursor: pointer;
}

.checked input[type='checkbox'] {
    display: none;
}

.checked .checkmark {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.checked .checkmark i {
    color: #fff;
    position: absolute;
}

input[type='checkbox']:disabled {
    opacity: 0.5;
}

.checked.rounded {
    border-radius: 50%;
}

.checked.rounded i {
    display: none;
}

.unchecked.rounded {
    border-radius: 50%;
}
