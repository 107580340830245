.container {
    background: #ffffff;
    border-radius: 10px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 40px;
}

.container .inputs {
    display: flex;
    gap: 40px;
}

.container .inputs .form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 50%;
}

.container .inputs .form .files {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 8px 12px;
    gap: 6px;
    border: 1px solid #012bd9;
    border-radius: 8px;
    color: #012bd9;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s;
}

.container .inputs .form .files span {
    color: #012bd9;
    transition: all 0.3s;
}

.container .inputs .form .files:hover {
    background: #012bd9;
    color: #fff;
}

.container .inputs .form .files:hover span {
    color: #fff;
}

.container .inputs .form .tasks {
    border: 1px solid #9b9b9b;
    width: 100%;
    border-radius: 4px;
    padding: 8px 10px;
    height: 180px;
    resize: none;
}

.container .inputs .form .tasks.summary {
    height: 524px;
}

.container .inputs .form .tasks:focus {
    border: 1px solid #012bd9;
}

footer {
    display: flex;
    gap: 20px;
    justify-content: flex-end;
}

.accordionSummary {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.accordionSummary .leftSide {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.accordionSummary .leftSide span {
    color: #9b9b9b;
}

.accordionSummary .leftSide .title {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 15px;
}

.accordionSummary .leftSide .title i {
    transform: rotate(0);
    transition: all 0.3s;
}

.accordionSummary .leftSide .title .rotated {
    transform: rotate(180deg);
}

.container .filesTable {
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s;
}

.container .filesTable.show {
    display: block;
    max-height: 3000px;
}

@media screen and (max-width: 768px) {
    .container {
        padding: 24px 12px;
    }

    .container .inputs {
        flex-direction: column;
    }

    .container .inputs .form {
        width: 100%;
    }

    .container .inputs .form .tasks.summary {
        height: 180px;
    }
}
