.container {
    margin-top: 24px;
    border: 1px solid #ecede8;
    border-radius: 10px;
    background: #ffff;
}

.container .summary {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.container .expanded {
    border-radius: 10px 10px 0 0;
}

.container .summary .title {
    display: flex;
    align-items: center;
    gap: 6px;
}

.container .summary .title h3 {
    font-weight: 900;
    font-size: 20px;
}

.container .summary .title i {
    color: #012bd9;
    font-size: 20px;
}
.container .summary .title i::before {
    margin-left: 0;
}

.container .summary button {
    width: auto;
    height: auto;
    background: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container .summary button span {
    font-size: 16px;
    background: #f2f4fd;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: block;
}

.container .summary button:hover span {
    color: #012bd9;
}

.container .text {
    margin-bottom: 24px;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    padding: 32px;
    text-transform: uppercase;
    line-height: 1.4;
    word-wrap: break-word;
}

.container .text ol,
.container .text ul {
    list-style: auto;
}

.container .text:hover {
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.2);
}
.container .text p {
    line-height: 1.3;
}

@media screen and (max-width: 768px) {
    .container {
        margin-top: 16px;
    }

    .container .summary .title h3 {
        font-size: 16px;
    }

    .container .summary .title i {
        font-size: 18px;
    }
}
