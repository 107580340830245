.topRight {
    margin: 30px 0;
}

.topRight button {
    margin-left: auto;
}

@media screen and (max-width: 768px) {
    .topRight {
        margin: 24px 16px;
        position: relative;
    }

    .topRight::before {
        content: '';
        display: block;
        position: absolute;
        top: -24px;
        left: -16px;
        width: calc(100% + 32px);
        height: 100px;
        z-index: 0;
    }

    .topRight button {
        width: 100%;
        margin: 0 auto;
        position: relative;
    }
}
