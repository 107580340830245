.container {
    display: flex;
    flex-direction: column;
    align-content: center;
    padding-bottom: 40px;
    height: calc(100vh - 80px);
    gap: 40px;
}

.container header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding-top: 40px;
    max-width: 1360px;
    margin: 0 auto;
}

.container header .leftSide {
    display: flex;
    align-items: center;
    gap: 15px;
}

.container header .leftSide i {
    padding: 6px 0;
    padding-right: 5px;
    cursor: pointer;
    transform: translateX(0);
    transition: all 0.3s;
}

.container header .leftSide i:hover {
    color: #012bd9;
    transform: translateX(-3px);
}

.container header .leftSide h1 {
    font-size: 32px;
    font-weight: 900;
}

.loaderContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.contentContainer {
    height: 100%;
    overflow: auto;
    width: 100%;
}

.contentContainer .content {
    max-width: 1360px;
    margin: auto;
}

/* width */
::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 20px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #bab9b9;
}

@media screen and (max-width: 1380px) {
    header,
    .content {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media screen and (max-width: 768px) {
    .container {
        gap: 16px;
        height: calc(100vh - 64px);
    }

    .content {
        padding: 0 16px 16px 16px;
    }

    .container header {
        padding: 20px;
        background: #ffff;
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
        gap: 10px;
    }

    .container header .leftSide h1 {
        font-size: 20px;
    }
}
