.tableWrapper {
    overflow: auto;
}

.tableWrapper .table {
    min-width: 1360px;
    width: 100%;
    position: relative;
}

.table td:last-child,
.table th:last-child {
    position: sticky;
    right: 0;
    z-index: 2;
    box-shadow: -2px 1px 1.8px rgba(0, 0, 0, 0.1), -2px 5px 7.2px rgba(0, 0, 0, 0.13);
    background-color: #ffff;
    width: 30px;
}

.table tr:hover td {
    background: #ecf1fc;
}

.table tr:hover td button {
    color: #0122ae;
}

.table td:last-child:before,
.table th:last-child:before {
    content: '';
    position: absolute;
    top: 0;
    left: -10px;
    width: 10px;
    height: 100%;
    background-image: linear-gradient(to right, transparent, #d3d3d3ad);
    z-index: 1;
}

.table th:last-child div {
    display: none;
}

.table tbody tr td {
    white-space: nowrap;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: #ffff;
    border-radius: 0 0 20px 20px;
    padding: 25px 30px;
}

.pagination .pagesAmount {
    display: flex;
    align-items: center;
    gap: 20px;
    width: 220px;
}

.pagination .pagesAmount span {
    color: #000 !important;
}

.pagination .emptyBlock {
    width: 220px;
}

.pagination .pagesAmount p {
    color: #9b9b9b !important;
    white-space: nowrap;
}
