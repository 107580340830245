.containerOptions {
    position: absolute;
    top: -50px;
    right: 48px;
    width: 160px;
    background: #ffff;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
    border-radius: 6px;
    padding: 8px;
    z-index: 9999;
}

.containerOptions li {
    font-size: 14px;
    font-weight: 400;
    color: #344054;
    cursor: pointer;
    padding: 6px;
    transition: all 0.3s;
}

.containerOptions li:hover {
    background: #ecf1fc;
    border-radius: 4px;
    color: #012bd9;
}

.containerOptions li.disabled,
.containerOptions li:hover.disabled {
    color: #9b9b9b;
    cursor: default;
    background: transparent;
}

.containerOptions::before {
    display: block;
    content: '';
    z-index: 9999;
    position: absolute;
    top: 50px;
    left: 98%;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #ffff;
}

/*Overlay*/

.overlay {
    background: transparent;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;
    transition: all 0.3s;
}
