.modalContent {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 290px;
    background: #ffffff;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    padding: 30px;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    z-index: 9999;
    cursor: default;
}

.modalContent h4 {
    font-weight: 900;
    font-size: 18px;
    padding-bottom: 6px;
}

.modalContent span {
    padding-bottom: 16px;
    line-height: 1.2;
}

.modalContent footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 16px;
}

@media screen and (max-width: 768px) {
    .modalContent {
        width: 250px;
        padding: 20px;
    }
}
