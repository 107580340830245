.modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 670px;
    max-height: 90%;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    padding: 40px 0;
    z-index: 3000;
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.modalContent .header {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 40px;
}

.modalContent .header .titleWrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
}

.modalContent .header .titleWrapper h1 {
    font-size: 32px;
    font-weight: 900;
}

.modalContent .header .addDocument {
    line-height: 1.3;
}

.modalContent .header .titleWrapper i {
    color: #9b9b9b;
    cursor: pointer;
    transition: all 0.3s;
}

.modalContent .header .titleWrapper i:hover {
    opacity: 0.7;
}

.modalContent .header .titleWrapper i::before {
    margin-right: 0;
}

.modalContent .error {
    margin: 0 40px;
    color: red;
    font-size: 15px;
}

.modalContent .upload {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    border: 1px dashed #9b9b9b;
    border-radius: 8px;
    height: 160px;
    cursor: pointer;
    padding: 30px 0;
    margin: 0 40px;
}

.modalContent .upload h4 {
    color: #012bd9;
    font-size: 16px;
    margin: 14px 0 12px;
}

.modalContent .upload p {
    color: #9b9b9b;
    font-size: 12px;
}

.modalContent .upload i {
    font-size: 42px;
    color: #012bd9;
}

.modalContent .filesContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    overflow: auto;
    padding: 0 40px 20px 40px;
}

.modalContent .filesContainer .loader {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    padding: 18px 18px 18px 40px;
}

.modalContent .filesContainer .loader:hover {
    box-shadow: 0px 0.3px 0.9px rgba(0, 0, 0, 0.1), 0px 1.6px 3.6px rgba(0, 0, 0, 0.2);
}

.modalContent .filesContainer .loader i {
    cursor: pointer;
    font-size: 13px;
    color: #012bd9;
    transition: all 0.3s;
}

.modalContent .filesContainer .loader .wrapperNameFile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
}

.modalContent .filesContainer .loader .wrapperNameFile i:hover {
    color: #061f83;
}
.modalContent .filesContainer .loader .wrapperNameFile .nameFile i:hover {
    cursor: auto;
}

.modalContent .filesContainer .loader .wrapperNameFile .nameFile {
    display: flex;
    align-items: center;
    position: relative;
}

.modalContent .filesContainer .loader .wrapperNameFile .nameFile i {
    margin-right: 4px;
    font-size: 16px;
    position: absolute;
    left: -26px;
    color: #344054;
}

.modalContent .filesContainer .loader .wrapperNameFile .nameFile h6 {
    word-break: break-all;
    padding-right: 15px;
    font-size: 14px;
}

.modalContent .filesContainer .loader .wrapperNameFile.withIconTrash i:last-of-type {
    font-size: 18px;
}

.modalContent .filesContainer .loader span,
.modalContent .filesUploaded span {
    color: #9b9b9b;
    font-size: 12px;
    font-style: italic;
}

.modalContent .filesContainer .loader .loaderBar {
    width: 100%;
    height: 4px;
    border-radius: 20px;
    background: #f7f7f8;
    position: relative;
    margin-bottom: 8px;
    margin-top: 4px;
}

.modalContent .filesContainer .loader .loaderBar .bar {
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 0;
    background: #012bd9;
}

.modalContent textarea {
    height: 100%;
    width: 100%;
    border: none;
    line-height: 20px;
    max-height: 100px;
    resize: none;
}

.modalContent .filesContainer .loader .smallTopText {
    position: absolute;
    top: 17px;
    z-index: 100;
    background: #fff;
    font-size: 14px;
    padding: 0 6px;
    color: #9b9b9b;
}

.modalContent footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px;
    padding: 0 40px;
}

.modalContent footer button {
    width: 120px;
}

/* width */
textarea::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

@media screen and (max-width: 768px) {
    .modalContent {
        width: 90%;
        padding: 24px 0;
    }

    .modalContent .header {
        padding: 0 16px;
    }

    .modalContent .error {
        margin: 0 16px;
    }

    .modalContent .upload {
        margin: 0 16px;
        padding: 20px 0;
    }

    .modalContent .filesContainer {
        padding: 0 20px;
    }

    .modalContent footer {
        padding: 0 20px;
    }

    .modalContent .titleWrapper h1 {
        font-size: 22px;
    }

    .modalContent .filesContainer .loader {
        margin: 22px 0;
    }

    .modalContent .upload h4 {
        margin: 6px 0 6px;
    }
}
